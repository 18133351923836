<template>
  <div id="knowledge-base-category-page">
    <p class="mb-4">{{ selectedCategory.description }}</p>

    <div class="vx-row mt-8 match-height">
      <div
        v-for="section in sections"
        :key="section.id"
        class="vx-col w-full sm:w-1/2 md:w-1/3 mb-base">
        <vx-card>
          <h4 class="mb-4">{{ section.title }}</h4>
          <ul class="bordered-items">
              <li
                v-for="que in section.questions"
                :key="que.question"
                class="py-2">
                <a
                  href="#"
                  @click.prevent="questionSelected(section, que)">
                  {{ que.title }}
                </a>
              </li>
          </ul>
        </vx-card>
        </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      selectedCategory: (state) => state.documentation.selectedCategory,
    }),
    sections() {
      return this.selectedCategory
        ? this.selectedCategory.sections || []
        : [];
    },
  },
  mounted() {
    if (!this.selectedCategory) {
      this.$router.push('/documentation').catch(() => {});
    } else {
      const title = `${this.$t('$General.Documentation')} (${this.selectedCategory.title})`;
      this.updateLastBreadcrumbItemTitle(title);
      this.$emit('changeRouteTitle', title || 'Category');
    }
  },
  methods: {
    ...mapActions({
      updateLastBreadcrumbItemTitle: 'updateLastBreadcrumbItemTitle',
      setSelectedQuestionInSection: 'documentation/setSelectedQuestionInSection',
    }),
    questionSelected(section, question) {
      this.setSelectedQuestionInSection({
        section,
        question,
      });
      this.$router.push(this.getQuestionUrl(question)).catch(() => {});
    },
    getQuestionUrl(question) {
      const questionEscaped = question.title
        ? question.title.toLowerCase().replaceAll(' ', '-')
        : question.question.id;
      return `/documentation/${this.selectedCategory.id}/${questionEscaped}`;
    },
  },
};
</script>
